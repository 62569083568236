







import {Component, Prop, Vue,} from 'vue-property-decorator'

@Component({})
export default class ResponsiveContainer extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) private readonly fillHeight!: boolean
  @Prop({ type: Boolean, required: false, default: false }) private readonly fluid!: boolean
  @Prop({ type: Boolean, required: false, default: true }) private readonly withDefaultPadding!: boolean
}
