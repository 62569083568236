








import Vue from 'vue'
import {Component} from "vue-property-decorator";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";

@Component({
  components: {ResponsiveContainer}
})
export default class DashboardPage extends Vue {

}
